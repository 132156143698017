.tokenDetails {
  display: flex;
  gap: 10px;
  margin: auto;
}
.token {
  padding: 8px 12px;
  border: 1px solid grey;
  display: flex;
  font-weight: 600;
  gap: 8px;
  font-size: 18px;
  align-items: center;
  border-radius: 8px;
}
