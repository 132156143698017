.apexcharts-tooltip {
  background: #f3f3f3;
  color: #192134;
}

.displayNone {
  display: none;
}
.chartHeader {
  font-size: 24px;
  color: white;
  font-weight: 600;
}
.chart-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 100%; */
}
.details-box {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  flex-direction: column;
  margin-left: 10px;
}
.totalval {
  font-size: 40px;
  font-weight: 700;
}
.time {
  font-size: 18px;
  font-weight: 700;
  height: 20px;
  opacity: 0.7;
}
.space-between {
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.lable {
  display: flex;
  gap: 5px;
  font-size: 16px;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

/* HTML: <div class="loader"></div> */

.box {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
