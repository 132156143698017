.App {
  text-align: center;
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo {
  width: 40px;
  height: 40px;
  padding-right: 20px;
}

.eth {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.wallet_btn {
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  border: 1px solid #80808063;
  width: 100%;
  margin: 0 auto;
  background-color: #2e212b;
  color: white;
  cursor: pointer;
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: "white";
  padding: 30px;
  border: none;
  border-radius: 16px;

  .close_icon {
    position: absolute;
    top: -10px;
    right: -12px;
    padding: 6px;
    font-size: 14px;
    color: #fff;
    border-radius: 52px;
    background: #555;
    /* box-shadow: -3px 4px 6px 0px rgba(0, 0, 0, 0.5); */
  }
}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.headerItem:hover {
  background-color: rgb(34, 42, 58);
  cursor: pointer;
}

wcm-modal {
  position: fixed;
  z-index: 1400;
}

.connectButton {
  background-color: #243056;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #5981f3;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #fff;
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  width: 400px;
  background-color: #0e111b;
  border: 2px solid #21273a;
  min-height: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}
.successBox {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.tokenDetailsBox {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 30px;
}

.amount {
  font-weight: 600;
  font-size: 20px;
}
.successBoxHeader {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 24px;
}
.tokenDetails {
  position: relative;
}
.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #3a4157;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 86px;
  left: 180px;
  color: #5f6783;
  border: 3px solid #0e111b;
  font-size: 12px;
  transition: 0.3s;
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5f6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 7px;
}

.assetOne {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 36px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 135px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.successassetOne {
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.successassetTwo {
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetOne:hover {
  cursor: pointer;
}

.assetTwo:hover {
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #243056;
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: #5981f3;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 8px;
}

.swapButton[disabled] {
  background-color: #243056;
  opacity: 0.4;
  color: #5982f39b;
}

.swapButton[disabled]:hover {
  cursor: not-allowed;
  background-color: #243056;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #3b4874;
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}

.errorBox {
  width: 380px;
  text-wrap: wrap;
  text-align: start;
  word-break: break-all;
}

.TokenList {
  width: calc(100% - 100px);
  padding: 0 50px 50px;
}
.redText {
  color: rgb(255, 95, 82);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.greenText {
  justify-content: flex-end;
  color: rgb(64, 182, 107);
  display: flex;
  align-items: center;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}

.spin-loader {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
