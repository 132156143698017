.createBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  padding: 30px 0;
}
.createBox fieldset {
  border-color: white;
  color: white;
}
.swapButton {
  width: 100%;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.562) !important;
}
input {
  color: white !important;
}
.MuiFormLabel-root {
  color: white !important;
}
label.Mui-focused {
  color: #1976d2 !important;
}
.MuiInputBase-root:hover fieldset {
  border-color: #1976d2 !important;
}
.MuiInputBase-root:hover .Mui-focused {
  border-color: #1976d2 !important;
}
.tab {
  display: flex;
}
.emsg {
  text-align: start;
}

.selectBox {
}
.Box {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
